// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E9UOGP1zTH9znR7MievH{display:flex;gap:72px;margin-bottom:8px;justify-content:space-between}@media(max-width: 768.02px){.E9UOGP1zTH9znR7MievH{flex-direction:column;gap:8px}}.E9UOGP1zTH9znR7MievH>*{flex:1 0 0}.E9UOGP1zTH9znR7MievH.pjIxBsT_PuEkXCssWjr_ :nth-child(2){text-align:right}.E9UOGP1zTH9znR7MievH .akXyrl0GcXwQUdxK3Chb{flex-direction:row;display:flex}.E9UOGP1zTH9znR7MievH .akXyrl0GcXwQUdxK3Chb .pwtSA3CeupjhqFcqOSie:after{width:22px;height:22px;display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Help-tip-gray.svg")}.E9UOGP1zTH9znR7MievH .akXyrl0GcXwQUdxK3Chb .pwtSA3CeupjhqFcqOSie{cursor:pointer;margin-left:5px;margin-top:-1px;vertical-align:middle}.E9UOGP1zTH9znR7MievH .akXyrl0GcXwQUdxK3Chb .pwtSA3CeupjhqFcqOSie:after{background-size:22px 22px}.E9UOGP1zTH9znR7MievH .WWx393nlbHEfItXPwRXB{flex:1 0 auto;max-width:100%;overflow:hidden;transition:max-height 400ms ease-in-out;max-height:0}.E9UOGP1zTH9znR7MievH .WWx393nlbHEfItXPwRXB.eICMqfaMA5UGuFpqe654{max-height:300px}.fmdOy6orRa36lvGiw8OR{margin-top:16px}.__agKEgF9IhO6pjnjqHj{font-weight:bold}@media(min-width: 0){.__agKEgF9IhO6pjnjqHj{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 400px){.__agKEgF9IhO6pjnjqHj{font-size:1.6rem;line-height:2.4rem}}@media(min-width: 768px){.__agKEgF9IhO6pjnjqHj{font-size:1.8rem;line-height:2.8rem}}@media(min-width: 1366px){.__agKEgF9IhO6pjnjqHj{font-size:1.9rem;line-height:2.8rem}}.pwtSA3CeupjhqFcqOSie:after{width:24px;height:24px;display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Help-tip-gray.svg")}.pwtSA3CeupjhqFcqOSie{cursor:pointer;width:24px;height:24px;display:block}.pwtSA3CeupjhqFcqOSie:after{background-size:24px 24px}`, ""]);
// Exports
export var row = `E9UOGP1zTH9znR7MievH`;
export var alignToRight = `pjIxBsT_PuEkXCssWjr_`;
export var taxInfo = `akXyrl0GcXwQUdxK3Chb`;
export var iconForHoveredText = `pwtSA3CeupjhqFcqOSie`;
export var taxInfoNote = `WWx393nlbHEfItXPwRXB`;
export var visibleTaxInfoNote = `eICMqfaMA5UGuFpqe654`;
export var moreMargin = `fmdOy6orRa36lvGiw8OR`;
export var monthlyCost = `__agKEgF9IhO6pjnjqHj`;
export default ___CSS_LOADER_EXPORT___;

import { MachinePaymentMode } from "ui/src/types";
import { Fragment } from "react";
import * as styles from './app.module.scss';
import { Card } from "./Components";
import { OrderConfirmationData } from "./types";

export const OrderSummary = ({ cart }: { cart: OrderConfirmationData }) => {
    const translations = window.app.preloadState.translation;

    return <Card>
        {cart.lineItems.map(item => <Fragment key={item.code}>
            <div className={styles.machineHeader}>
                <span>{item.quantity}</span>
                <span>x</span>
                <span>{item.displayName}</span>
            </div>
            {item.paymentMode === MachinePaymentMode.Direct
                ? <div className={styles.machineRow}>
                    <span>{translations["machineSalesCheckout.directPurchase"]}</span>
                    <span>{item.discountedPrice}</span>
                </div>
                : <>
                    <div className={styles.machineRow}>
                        <span>{translations["machineSalesCheckout.monthlyCost"]}</span>
                        <span>{item.financeOptions.monthlyPaymentWithCurrency}</span>
                    </div>
                    <div className={styles.machineRow}>
                        <span>{translations["machineSalesCheckout.firstInstalment"]}</span>
                        <span>{item.financeOptions.discountedFirstInstalmentWithCurrency}</span>
                    </div>
                </>}
            {/*{cart.voucher && item.voucherDiscount && <div className={styles.machineRow}>*/}
            {/*    <span>{translations["machineSalesCheckout.discount"]} ({cart.voucher})</span>*/}
            {/*    <span>- {item.voucherDiscount}</span>*/}
            {/*</div>}*/}
        </Fragment>)}
        <div className={styles.deliveryRow}>
            <span>{translations["machineSalesCheckout.delivery"]}</span>
            <span>{cart.summary.deliveryCost}</span>
        </div>
        <hr/>
        <div className={styles.totalRow}>
            <span>{translations["machineSalesCheckout.total"]} {translations["machineSalesCheckout.directPurchase"]}</span>
            <span>{cart.summary.totalCost}</span>
        </div>
        <div className={styles.totalRow}>
            <span>{translations["machineSalesCheckout.total"]} {translations["machineSalesCheckout.monthlyCost"]}</span>
            <span>{cart.summary.totalMonthlyCost}</span>
        </div>
        <div className={styles.totalRow}>
            <span>{translations["machineSalesCheckout.total"]} {translations["machineSalesCheckout.firstInstalment"]}</span>
            <span>{cart.summary.totalFirstInstallment}</span>
        </div>
    </Card>
};
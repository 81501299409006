import * as styles from "./app.module.scss";
import { OrderSummary } from "./IStep";
import cn from 'classnames';
import { MachineCartDeliveryMethod, MachineLineItem } from "ui/src/types";
import { Item, Note } from "./Components";
import { Fragment } from "react";
import { InputCheckbox, InputSelect, InputText, Rating } from "ui";
import { CustomerInformation, DeliveryMethod, OrderConfirmationData } from "./types";

const translations = window.app.preloadState.translation;

export default function App() {
    const orderConfirmationData: OrderConfirmationData = window.app.preloadState.machineSalesOrderConfirmation;

    const sendRating = async (rate: number) => {
        const response = await fetch(`/api/machinesales/order/${orderConfirmationData.id}/rate?rate=${rate}`, {
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            method: "PUT"
        });
        if (response.status !== 204) {
            console.error(response.statusText, await response.text());
        } else {
            // Matomo
        }
    };

    return <div className={styles.wrapper}>
        <Rating value={orderConfirmationData.rating} onSend={sendRating} />
        <h1 className={styles.title}>{translations["machineSalesCheckout.thankYouForOrder"]}</h1>
        <div className={styles.headerText} dangerouslySetInnerHTML={{ __html: orderConfirmationData.thankYouMessage ?? "" }}></div>
        {orderConfirmationData != null && <OrderConfirmation data={orderConfirmationData} />}
    </div>
}

function OrderConfirmation({ data }: {data: OrderConfirmationData }) {
    const loggedIn = window.app.preloadState.isLoggedIn;

    const userInformation = (name: keyof CustomerInformation) => ({ value: data.customerInformation[name] as any ?? "" });
    const deliveryInformation = (name: keyof DeliveryMethod) => ({ value: data.deliveryMethod[name] as any ?? "" });

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.pageTitle}></h1>
            <div className={styles.checkoutGrid}>
                <div className={cn(styles.checkoutGridMainArea)}>
                    <div>
                        <div className={styles.headingContainer}>
                            <h3>{translations["machineSalesCheckout.userInformation"]}</h3>
                        </div>
                        {!loggedIn && <div className={styles.horizontalInputsGroup}>
                            <InputText readonly label={translations["machineSalesCheckout.firstName"]} name="firstName" {...userInformation("firstName")} />
                            <InputText readonly label={translations["machineSalesCheckout.lastName"]} name="lastName" {...userInformation("lastName")} />
                        </div>}
                        <InputText readonly label={translations["machineSalesCheckout.companyName"]} name="companyName" {...userInformation("companyName")} />

                        <div className={styles.horizontalInputsGroup}>
                            <InputText readonly label={translations["machineSalesCheckout.email"]} name="email" {...userInformation("emailAddress")} />
                            <InputText readonly label={translations["machineSalesCheckout.phoneNumber"]} name="phoneNumber" {...userInformation("phoneNumber")} />
                        </div>
                        <div className={styles.horizontalInputsGroup}>
                            <InputText readonly label={translations["machineSalesCheckout.referenceNumber"]} name="referenceNumber" {...userInformation("referenceNumber")} />
                            <InputText readonly label={translations["machineSalesCheckout.purchaseNumber"]} name="purchaseNumber" {...userInformation("purchaseNumber")} />
                        </div>
                        {loggedIn && <InputText readonly label={translations["machineSalesCheckout.invoiceAddress"]} name="invoiceAddress" {...userInformation("invoiceAddress")} disabled={true} />}
                        {!loggedIn && <>
                            <InputText readonly label={translations["machineSalesCheckout.streetAndNumber"]} name="streetAndNumber" {...userInformation("streetAndNumber")} />

                            <div className={styles.horizontalInputsGroup}>
                                <InputText readonly label={translations["machineSalesCheckout.town"]} name="town" {...userInformation("town")} />
                                <InputText readonly label={translations["machineSalesCheckout.zipCode"]} name="zipCode" {...userInformation("zipCode")} />
                            </div>
                            <InputSelect disabled={true} label={translations["machineSalesCheckout.country"]} name="country" options={[
                                { label: 'Sweden', value: 'se' },
                                { label: 'Germany', value: 'de' }
                            ]} {...userInformation("country")} />
                        </>
                        }

                        <Note className={styles.topMarginForTextArea} placeholder="" value={data.note ?? ""} />
                    </div>
                    <div>
                        <div className={styles.headingContainer}>
                            <h3>{translations["machineSalesCheckout.deliveryMethod"]}</h3>
                        </div>
                        {data.deliveryMethod.deliveryMethodType === MachineCartDeliveryMethod.Delivery && <>
                            <InputCheckbox label={translations["machineSalesCheckout.delivery"]} name="deliveryMethod" checked={true} disabled={true} />
                            <div className={cn(styles.methodGroup, styles.disabled)}>
                                {!loggedIn ? <>
                                    <div className={styles.horizontalInputsGroup}>
                                        <InputText readonly label={translations["machineSalesCheckout.firstName"]} name="firstName" {...deliveryInformation("firstName")} />
                                        <InputText readonly label={translations["machineSalesCheckout.lastName"]} name="lastName" {...deliveryInformation("lastName")} />
                                    </div>
                                    <InputText readonly label={translations["machineSalesCheckout.streetAndNumber"]} name="streetAndNumber" {...deliveryInformation("streetAndNumber")} />
                                    <div className={styles.horizontalInputsGroup}>
                                        <InputText readonly label={translations["machineSalesCheckout.town"]} name="town" {...deliveryInformation("town")} />
                                        <InputText readonly label={translations["machineSalesCheckout.zipCode"]} name="zipCode" {...deliveryInformation("zipCode")} />
                                    </div>
                                    <InputSelect disabled={true} label={translations["machineSalesCheckout.country"]} name="country" options={[
                                        { label: 'Sweden', value: 'se' },
                                        { label: 'Germany', value: 'de' }
                                    ]} {...deliveryInformation("country")} />
                                </> : <>
                                    <InputSelect disabled={true} label={translations["machineSalesCheckout.deliveryAddress"]} name="deliveryAddress" options={[
                                        { label: data.deliveryMethod.address, value: data.deliveryMethod.deliveryAddressId }
                                    ]} {...deliveryInformation("address")} />
                                </>}
                            </div>
                        </>
                        }
                        {data.deliveryMethod.deliveryMethodType === MachineCartDeliveryMethod.PickUp && <>
                            <InputCheckbox label={translations["machineSalesCheckout.pickUpInStore"]} name="deliveryMethod" checked={true} />
                            <div className={cn(styles.methodGroup, styles.disabled)}>
                                <InputSelect disabled={true} label={translations["machineSalesCheckout.branchAddress"]} name="branchAddress" options={[
                                    { label: data.deliveryMethod.address, value: data.deliveryMethod.deliveryBranchId }
                                ]} {...deliveryInformation("address")} />
                            </div>
                        </>}
                    </div>
                    <div>
                        <div className={styles.headingContainer}>
                            <h3 className={styles.shoppingCartTitle}>{translations["machineSalesCheckout.shoppingCart"]}</h3>
                        </div>
                        <div className={styles.itemList}>
                            {data.lineItems.map((product: MachineLineItem, index) => <Fragment key={product.code}>
                                {index > 0 && <hr />}
                                <Item readonly productItem={product} />
                            </Fragment>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.checkoutGridDetails}>
                    <OrderSummary cart={data} />
                </div>
            </div>
        </div>
    );
}

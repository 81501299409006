import * as styles from './Inputs.module.scss';
import cn from 'classnames';

type InputProps<T extends React.Key> = {
    name: string,
    label: string,
    disabled?: boolean,
    value?: T,
    className?: string,
    error?: string;
    onChange?: (value: T) => void;
    onBlur?: (e: React.FocusEvent) => void;
    placeholder?: string;
    options: {
        label: string;
        value: InputProps<T>['value'];
    }[]
};


export function InputSelect<T extends React.Key>({
    name,
    label,
    disabled = false,
    value,
    className,
    error,
    onChange,
    onBlur,
    placeholder,
    options
}: InputProps<T>) {
    return (<div className="form-select">
        <span className={cn(styles.selectLabel, "dropdown__label")}>{label}</span>
        <select name={name} className={cn(className, disabled && styles.disabled)} disabled={disabled} value={value?.toString() ?? ""} onChange={ev => {
            onChange?.((ev.target.value) as T);
            onBlur?.(ev as any as React.FocusEvent);
        }}>
            <option value="" disabled hidden>{placeholder}</option>
            {options.map(option => <option value={typeof option.value === 'bigint' ? option.value.toString() : option.value} key={option.value}>
                {option.label}
            </option>)}
        </select>
        {error && <span className="form-error">{error}</span>}
    </div>)
}
import { MachineLineItem, MachinePaymentMode } from 'ui/src/types';
import * as styles from './Item.module.scss';
import { ButtonResult, ButtonType, Design, FinancePopUpBody, Mode, PopupSize, QuantitySelector, popup } from 'ui';
import cn from 'classnames';

export const Item = ({ productItem, readonly }: { productItem: MachineLineItem, readonly?: boolean }) => {
    const translations = window.app.preloadState.translation;

    const showFinancingPopUp = () => {
        const financingPopUpData = window.app.preloadState.financingPopUp;

        popup(
            translations["financeOptions.header"],
            <FinancePopUpBody serialNumber={productItem.code} readonly={true} />,
            [
                { label: translations["financeOptions.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined }
            ],
            PopupSize.ExtraVertical,
            undefined,
            {
                content: <div className={styles.info}>
                    <div>
                        <span>{translations["financeOptions.email"]}: {financingPopUpData.financingPopUpEmailAddress}</span>
                    </div>
                    <div>
                        <span>{translations["financeOptions.phone"]}: {financingPopUpData.financingPopUpPhoneNumber}</span>
                    </div>
                </div>,
                reverse: true
            }
        );
    }

    return <div className={styles.item}>
        <div className={styles.imageContainer}>
            <img src={productItem.imageUrl ?? ""} /></div>
        <div className={styles.statsContainer}>
            <div className={styles.topStatsContainer}>
                <a href={productItem.url} className={styles.title}>{productItem.displayName}</a>
                <p className={styles.delivery}>{translations["machineSalesCheckout.deliveryApprox"]} {productItem.machineDeliveryInfo}</p>
            </div>
            <div>
                <div className={styles.selector}>
                    <p>
                        <span className={"d-none d-md-block"}>{translations["machineSalesCheckout.quantity"]}:</span>
                    </p>
                    <QuantitySelector
                        onChange={() => { }}
                        onRemove={() => { }}
                        initialValue={1}
                        max={999}
                        min={1}
                        disabled={readonly}
                        mode={Mode.Buy}
                        step={1}
                        requestQuotationByEmail={""}
                        contactSupportLink={""}
                        showRemanufacturePopUp={undefined}
                        forceMinValue={false}
                        design={Design.Normal}
                    />
                </div>
            </div>
            <div className={styles.bottomStatsContainer}>
                {productItem.paymentMode === MachinePaymentMode.Direct ? <>
                    <p>{translations["machineSalesCheckout.fullAmountTotal"]}</p>
                    <p className={styles.price}>{productItem.discountedPrice}</p>
                </> : <>
                    <p>{translations["machineSalesCheckout.totalMonthlyCost"]}</p>
                    <p className={styles.price}>{productItem.financeOptions.monthlyPaymentWithCurrency}</p>
                    <span onClick={showFinancingPopUp} className={styles.showFinanceOptions}>{translations["machineSalesCheckout.linkToShowFinancingPopup"]}</span>
                </>
                }
            </div>
        </div>
        <div className={cn(styles.trashContainer)}>
            {!readonly && <span className={styles.removeItemIcon}></span>}
        </div>
    </div>;
}